import { mainStyles, divText } from './disclaimers.module.css'
import React from "react"
import Footer from "../../components/Footer"
import NavBar from "../../components/NavBar"
import { Helmet } from "react-helmet"

const IndexPage = () => {
  return (
    <div className={mainStyles}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Disclaimers - Fede Cocina</title>
          <meta name="description" content="DISCLAIMERS"/>
          <html lang="es"/>
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8506919019942528" crossorigin="anonymous"></script>
        </Helmet>
      <NavBar></NavBar>
      <div className={divText}>
        <p><strong>Disclaimer Nutricional</strong></p>
        <p>En todas las recetas, la información nutricional, como las calorías, la grasa, los carbohidratos, etc., aparecen en el sitio web. Esta información es generada por un algoritmo que reconoce los nombres y las cantidades de los ingredientes y hace cálculos basados en el tamaño de la porción correspondiente. Aunque esta información puede ser útil para estimar la información nutricional de una receta, no es en absoluto exacta ni oficial y no está evaluada por ninguna administración oficial. Como estas cifras son solo estimaciones, el lector debe ser advertido de que el contenido nutricional puede variar debido a varios factores como la cantidad de un ingrediente que se utiliza frente a la cantidad que realmente se va a comer, las diferencias de marca, y más. Para garantizar la información nutricional más precisa, el lector debe hacer los cálculos basándose en los ingredientes realmente empleados, usando su propio método preferido o consultando con un experto en nutrición autorizado. Este sitio web no pretende ser una fuente de asesoramiento nutricional o de seguridad alimentaria, y sus lectores asumen toda la responsabilidad de las decisiones que tomen en relación con su propia salud y seguridad.</p>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default IndexPage
